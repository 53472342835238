.termsofservice-all-content-alignment {
    padding: 100px 0;
    .new-container {
        max-width: calc(1280px + 40px );
        padding: 0 20px;
        margin: 0 auto;
    }

    h1 {
        font-size: 32px;
        font-weight: 600;
        color: #121212;
        margin: 0 0 24px 0;
    }

     p {
        font-size: 16px;
        line-height: 28px;
        color: #444;
        margin: 0 0 24px 0;
     }

     ul {
        margin: 0;
        padding: 0 0 0 20px;
        list-style: disc;
        li {
            font-size: 16px;
            line-height: 28px;
            display: block;
            color: #444;
            padding: 0 0 16px 0;
        }
     }
    h2 {
        font-size: 22px;
        font-weight: 600;
        margin: 0 0 24px 0;
        color: #121212;
    }
}